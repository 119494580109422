/**
 * Created by jerry on 2020/04/20.
 * 项目价格调整api
 */
import * as API from '@/api/index'

export default {
    // 获取项目价格调整列表
    getProjectPriceAdjustment: params => {
        return API.POST('api/projectPriceAdjustment/list',params)
    },
    // 新增项目价格调整
    createProjectPriceAdjustment: params => {
        return API.POST('api/projectPriceAdjustment/create',params)
    },
    // 更新项目价格调整
    updateProjectPriceAdjustment: params => {
        return API.POST('api/projectPriceAdjustment/update',params)
    },
    // 删除项目价格调整
    deleteProjectPriceAdjustment: params => {
        return API.POST('api/projectPriceAdjustment/delete',params)
    }
}
